import { useDareContext } from 'root/src/client/web/list/listItemComponents/dare/contexts/dare'
import { comingSoonMaskText } from 'root/src/shared/constants/dropEvent'
import {
	useMemo,
	useState,
} from 'react'
import { statusNamesEnum } from 'root/src/client/web/list/listItemComponents/project/ProjectCardLabel'
import {
	projectAcceptedKey,
	projectApprovedKey,
	projectDeliveredKey,
	projectDeliveryPendingKey,
	projectPendingKey,
	projectRejectedKey,
} from 'root/src/shared/constants/keys'
import moment from 'moment-mini'
import { notNil } from 'root/src/shared/util/ramdaPlus'
import providers from 'root/src/shared/constants/providers'
import { getConnectedPlatforms } from 'root/src/client/web/record/util/getConnectedPlatforms'
import {
	useBlackCardContext,
} from 'root/src/client/v2/common/components/BlackCard/hooks/useBlackCardContext'
import { useIsAuthenticated, useUser } from 'root/src/client/v2/common/hooks/useUser'
import { useIsMaxDaresReachedByPlatform } from 'root/src/client/logic/dropEvent/hooks/useIsMaxDaresReached'
import { useMaxDaresByPlatform } from 'root/src/client/logic/dropEvent/hooks/useMaxDaresByPlatform'

/**
 * Returns dare from contexts
 * @return {Dare}
 */
export const useDare = () => {
	const dareCardContext = useDareContext()

	const { dare } = dareCardContext

	if (dareCardContext.comingSoon) {
		return {
			...dare,
			product: {
				...dare.product,
				name: comingSoonMaskText,
			},
			title: comingSoonMaskText,
			description: comingSoonMaskText,
		}
	}

	return dare
}

const getDareCardLabels = (dare, ctx) => {
	const { status } = dare

	const timeLeftMod = ctx.timeLeft
		|| ctx.expirationDate && moment(ctx.expirationDate).diff(moment().format(), 'seconds')

	const pendingStatus = status === projectPendingKey
	const acceptedStatus = status === projectAcceptedKey
	const deliveryPendingStatus = status === projectDeliveryPendingKey
	const completedStatus = status === projectDeliveredKey
	const expiredStatus = !completedStatus && timeLeftMod <= 0
	const rejectedStatus = status === projectRejectedKey

	return {
		[statusNamesEnum.pending]: pendingStatus,
		[statusNamesEnum.accepted]: acceptedStatus,
		[statusNamesEnum.inReview]: deliveryPendingStatus,
		[statusNamesEnum.delivered]: completedStatus,
		[statusNamesEnum.rejected]: rejectedStatus,
		[statusNamesEnum.expired]: expiredStatus,
	}
}

const isInteracted = (ctx) => {
	const acceptedStatus = (!ctx.projectDeliveries
		&& !ctx.projectCompleted
		&& !ctx.projectDeliveryPending
		&& ctx.projectAccepted) || ctx.projectStatus === projectApprovedKey
	const completedStatus = ctx.projectDeliveries || ctx.projectCompleted
	const expiredStatus = !completedStatus && ctx.timeLeft <= 0
	const deliveryPendingStatus = ctx.projectDeliveryPending
	const noPrizesStatus = notNil(ctx.prizeAvailable) ? !ctx.prizeAvailable : false
	const rejectedStatus = ctx.projectStatus === projectRejectedKey
	const pendingStatus = ctx.projectStatus === projectPendingKey
	const interactedStatus = acceptedStatus || completedStatus || pendingStatus
		|| expiredStatus || deliveryPendingStatus || noPrizesStatus || rejectedStatus

	return Boolean(interactedStatus)
}

export const useDareCard = () => {
	const [isLoading, setIsLoading] = useState(false)
	const user = useUser()
	const blackCardContext = useBlackCardContext()
	const dareCardContext = useDareContext()
	const dare = useDare()
	const maxDaresByDarePlatform = useMaxDaresByPlatform({ platform: dare.platform })
	const isAuthenticated = useIsAuthenticated()
	const isMaxDaresReached = useIsMaxDaresReachedByPlatform({ platform: dare.platform })
	const { isAbleToParticipate = true, canAccessCreateBrandProject } = dareCardContext

	const isDarePlatformAvailable = useMemo(() => {
		const userPlatforms = getConnectedPlatforms(dareCardContext?.tokens)

		if (userPlatforms.length > 0 && dare.platform === providers.any) {
			return true
		}

		if (!dare.platform || dareCardContext.canAccessCreateBrandProject) {
			return true
		}
		return userPlatforms.includes(dare.platform)
	}, [dare, dareCardContext.platform, dareCardContext.tokens, dareCardContext.canAccessCreateBrandProject])

	const labelsMap = getDareCardLabels(dare, dareCardContext)
	const onAccept = async () => {
		setIsLoading(true)
		await dareCardContext.onClick(blackCardContext.setIsHovered)
		setIsLoading(false)
	}

	const isDareButtonEnabled = getIsDareButtonEnabled({
		canAccessCreateBrandProject,
		isAuthenticated,
		maxDares: maxDaresByDarePlatform,
		isAbleToParticipate,
		isDarePlatformAvailable,
		isMaxDaresReached,
		isComingSoon: dareCardContext.comingSoon,
	})

	return {
		dare,
		actions: {
			goToDareForm: dareCardContext.redirectAndPrePopulate,
			onAccept,
		},
		extraData: {
			dareCardContext,
			brand: dareCardContext.brand,
			game: dare.games[0],
			user: {
				userId: user?.authentication?.userId,
				isDareButtonEnabled,
				isMaxDaresReached,
				isAbleToParticipate,
				isEmailSubscribing: dareCardContext.emailSubscription,
				isEligibleToEarnPoints:
					dareCardContext.isEligibleToEarnPoints && dareCardContext.pointsToEarn > 0,
				isEligibleToCreateDare: dareCardContext.canAccessCreateBrandProject,
				isAdmin: dareCardContext.isAdmin,
				pointsToEarn: dareCardContext.pointsToEarn,
				maxDares: maxDaresByDarePlatform,
				isDarePlatformAvailable,
				isAuthenticated,
			},
			campaignId: dareCardContext.campaignId,
			labels: Object.entries(labelsMap).filter(([, value]) => value).map(([key]) => key),
			isOutOfStock: !dareCardContext?.dare?.prizeAvailable
				&& !dareCardContext.projectAccepted
				&& !dareCardContext.comingSoon,
			timeLeft: dareCardContext.timeLeft
				|| (dare.expirationDate && moment(dare.expirationDate).diff(moment().format(), 'seconds')),
			isInteracted: isInteracted(dareCardContext),
		},
		isComingSoon: dareCardContext.comingSoon,
		isLoading,
	}
}

/**
 * @typedef Dare
 * @type {Object}
 * @property {string} title
 * @property {string} description
 * @property {string} status
 * @property {string} type
 * @property {string} level
 * @property {string} rewardFor
 * @property {string} [platform]
 * @property {string} [hint]
 * @property {string} [estimatedTime]
 * @property {{ id: string, label: string, image: string }[]} games
 * @property {{ id: string, name: string, type: string  }} product
 * @property {{ available: number, used: number }} capacity
 */

/**
 * Returns true if dare button is enabled
 * @param {{ canAccessCreateBrandProject: boolean, isAuthenticated: boolean, maxDares: number, isAbleToParticipate: boolean, isDarePlatformAvailable: boolean, isMaxDaresReached: boolean, isComingSoon: boolean }} params
 * @return {boolean}
 */
function getIsDareButtonEnabled(params) {
	if (params.isComingSoon) {
		return true
	}
	if (params.canAccessCreateBrandProject || !params.isAuthenticated) {
		return true
	}
	if (!params.maxDares || !params.isAbleToParticipate || !params.isDarePlatformAvailable || params.isMaxDaresReached) {
		return false
	}
	return true
}
